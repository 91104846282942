import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string
        siteUrl: string
        social: { twitter: string }
      }
    }
  }
}

const AboutPage = ({ data }: Props) => {
  const {
    title: siteTitle,
    siteUrl,
    social: { twitter },
  } = data.site.siteMetadata

  return (
    <Layout title={siteTitle} twitter={twitter}>
      <SEO title="About" url={siteUrl} />
      <section className="markdown pb-12">
        <p>
          Naoto Yoneda 1989年大阪府大阪市生まれ。
          <br />
          関西大学卒業後、株式会社南都銀行入行。
          <br />
          8ヶ月後退職し、株式会社エウレカに入社。
          <br />
          3年後の2016年、フリーランスとして独立。
          <br />
          <br />
          レシピ動画アプリ <code className="language-text">iOS × Swift</code>
          <br />
          漫画アプリ <code className="language-text">iOS × Swift</code>
          <br />
          日程調整アプリ <code className="language-text">Rails × Ruby</code>
          <br />
          ライブ配信アプリ <code className="language-text">
            Goa × Golang
          </code> & <code className="language-text">Vue × Javascript</code>
          <br />
          ビジネスマッチングアプリ{" "}
          <code className="language-text">iOS × Swift</code> &{" "}
          <code className="language-text">Rails × Ruby</code>
        </p>
        <p>
          など多数のアプリケーションの開発を経験したのち、2018年株式会社ゼロイチラボ設立。
          <br />
          2019年株式会社ゼロイチラボ事業開始。
        </p>
        <hr />
        <h3>💼 Experience</h3>
        <table className="w-full">
          <tr className="text-left">
            <th className="w-1/3 font-normal bg-gray-100 px-4 py-2">
              2012/4 - 2012/12
            </th>
            <td className="px-4 py-2">株式会社南都銀行</td>
          </tr>
          <tr className="text-left">
            <th className="w-1/3 font-normal bg-gray-100 px-4 py-2">
              2013/1 - 2016/3
            </th>
            <td className="px-4 py-2">株式会社エウレカ</td>
          </tr>
          <tr className="text-left">
            <th className="w-1/3 font-normal bg-gray-100 px-4 py-2">
              2016/4 - 2019/9
            </th>
            <td className="px-4 py-2">フリーランス</td>
          </tr>
          <tr className="text-left">
            <th className="w-1/3 font-normal bg-gray-100 px-4 py-2">
              2019/10 -
            </th>
            <td className="px-4 py-2">株式会社ゼロイチラボ</td>
          </tr>
        </table>
        <h3>📚 Education</h3>
        <table className="w-full">
          <tr className="text-left">
            <th className="w-1/3 font-normal bg-gray-100 px-4 py-2">
              2008/4 - 2012/3
            </th>
            <td className="px-4 py-2">関西大学商学部</td>
          </tr>
        </table>
      </section>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
        }
      }
    }
  }
`
